import { compareVersions } from "compare-versions";
import { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";

import { cn } from "@/utils/cn.ts";
import { SAMSUNG_BROWSER_MINIMUM_REQUIRED_VERSION } from "@/utils/constants";

export const UnsupportedBrowserModal = () => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(isUnsupportedBrowser());
  }, []);

  const isUnsupportedBrowser = () => {
    const samsungBrowser = "Samsung";
    const { browser } = UAParser(navigator.userAgent);

    if (!browser.name || !browser.version) {
      return true;
    }

    const isSamsung = browser.name.includes(samsungBrowser);

    return (
      isSamsung &&
      compareVersions(
        browser.version,
        SAMSUNG_BROWSER_MINIMUM_REQUIRED_VERSION,
      ) < 0
    );
  };

  return (
    show && (
      <div
        className="modal fixed inset-0 bg-[rgba(37,40,48,0.5)] flex items-center justify-center z-50"
        aria-modal="true"
        role="dialog"
      >
        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md max-h-full mx-4 relative overflow-auto">
          <h3
            className={cn(
              "mb-[29px] font-semibold text-base leading-[1.5555] sm:text-md sm:leading-[1.4] sm:mb-[36px]",
            )}
          >
            Warning
          </h3>
          <div
            className={cn(
              "font-medium text-[16px] [&_p]:leading-[1.75] [&_p:not(:last-child)]:mb-[26px]",
              "sm:[&_p]:leading-[1.555] sm:[&_p:not(:last-child)]:mb-[32px]",
            )}
          >
            <div>
              Your browser is unsupported. Please download and use one of
              supported browsers:
            </div>
            <ul className="list-disc list-outside mb-4 ms-4 text-[16px] leading-[1.5] [&_a]:underline [&_a:hover]:no-underline">
              <li className="mt-2">
                <a href="https://play.google.com/store/apps/details?id=com.android.chrome">
                  Google Chrome
                </a>
              </li>
              <li className="mt-2">
                <a
                  className=""
                  href="https://play.google.com/store/apps/details?id=org.mozilla.firefox"
                >
                  Mozilla Firefox
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  );
};
